import TermsOfService from "components/TermsOfService";
import React, { FC, useEffect } from "react";

const TermsOfServicePage: FC<{}> = () => {
  useEffect(() => {
    document.title = "サービス利用規約";
  }, []);

  return (
    <>
      <div className="content-wrap">
        <TermsOfService />
        <footer className="footer">© Sansan, Inc.</footer>
      </div>
    </>
  );
};

export default TermsOfServicePage;
