import TermsOfServicePage from "pages/TermsOfServicePage";
import UploadPage from "pages/UploadPage";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router";

const App: FC<{}> = () => (
  <Switch>
    <Route path="/terms-of-service" component={TermsOfServicePage} />
    <Route path="/" component={UploadPage} />
    <Redirect to="/" />;
  </Switch>
);

export default App;
