import Axios, { AxiosInstance } from "axios";
import * as AxiosLogger from "axios-logger";
import { parse } from "query-string";
import { UploadFile } from "../components/UploadForm";
import { ApiClientBase } from "./ApiClientBase";

export class JesApiClient implements ApiClientBase {
  private static _instance: JesApiClient;

  private client: AxiosInstance;

  private constructor() {
    this.client = Axios.create();

    const token = parse(window.location.search).cid;

    this.client.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

    this.client.defaults.headers.common.Accept = "application/json";
    this.client.defaults.headers.common["Content-Type"] = "application/json";
    this.client.defaults.headers.common["X-WEB-UPLOAD-TOKEN"] = token;

    this.client.interceptors.request.use(AxiosLogger.requestLogger);

    this.client.interceptors.response.use(
      (res) => {
        AxiosLogger.responseLogger(res);
        return res;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  public static get instance() {
    if (!this._instance) {
      this._instance = new JesApiClient();
    }
    return this._instance;
  }

  public myOrganization() {
    return this.client.get("/external_api/web_upload/v1/contract_organizations/me").then((res) => res.data);
  }

  public me() {
    return Promise.resolve({
      uid: "",
    });
  }

  public uploadId() {
    return this.client.post("/external_api/web_upload/v1/upload_identifiers").then((res) => res.data);
  }

  public uploadAll(
    uploadFiles: UploadFile[],
    uploadId: string,
    companyNameValue: string,
    email: string,
    expressFlag: boolean
  ) {
    return Promise.all(
      uploadFiles.map((file) =>
        this.post(file, uploadId, companyNameValue, email, expressFlag).then(
          (res) => res.data,
          () => null
        )
      )
    );
  }

  private post(file: File, uploadId: string, companyNameValue: string, email: string, expressFlag: boolean) {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("upload_id", uploadId);
    formData.append("company_name", companyNameValue);
    formData.append("email", email);
    formData.append("express", JSON.stringify(expressFlag));

    return this.client.post("/external_api/web_upload/v1/source_files", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
