import React, { FC } from "react";
import "./AboutForSansan.css";

export type AboutForSansanProps = {
  setShowHelpModal: (value: boolean) => void;
};

const AboutForSansan: FC<AboutForSansanProps> = ({ setShowHelpModal }) => {
  return (
    <div className="about">
      自社の人事異動に関する資料を送ると、記載されている人物の名刺所有者に「人事異動・連絡先変更」のニュースを配信できます。
      {/* NOTE: button タグにすると CSS が崩れるため、a タグのままにする */}
      {/* https://reactrouter.com/web/example/modal-gallery を参考に react-router-dom を使ってモーダルを表示する方法もありそう */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="about__icon-help" onClick={() => setShowHelpModal(true)}>
        ?
      </a>
    </div>
  );
};

export default AboutForSansan;
