import "./Modal.css";
import AttentionIcon from "img/icon-attention.svg";

import React from "react";

export type ModalProps = {
  heading: string;
  text: string;
  list: string[];
  okText?: string;
  okHandler?: () => void;
  cancelText: string;
  cancelHandler: () => void;
};

export const initialProps: ModalProps = {
  heading: "",
  text: "",
  list: [],
  cancelText: "",
  cancelHandler: () => null,
};

const isEmpty = (props: ModalProps): boolean => {
  return props.heading === "" || props.text === "" || props.cancelText === "";
};

const Modal: React.FC<ModalProps> = (props) => {
  return isEmpty(props) ? null : (
    <div className="modal">
      <div className="modal__box">
        <div className="modal__btn-close" onClick={props.cancelHandler} />
        <div className="modal__message">
          <p className="message__heading">
            <img src={AttentionIcon} className="icon-attention" />
            {props.heading}
          </p>
          <div className="message__text">
            <p>{props.text}</p>
            <div className="message__list">
              <ul>
                {props.list.map((str, i) => (
                  <li key={i.toString()}>{str}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <form className="modal__btn">
          {props.okText && props.okHandler ? (
            <button type="button" className="input__btn ok" onClick={props.okHandler}>
              {props.okText}
            </button>
          ) : null}
          <button type="button" className="input__btn cancel" onClick={props.cancelHandler}>
            {props.cancelText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
