import React, { FC } from "react";
import "components/UploadForm/InputForm.css";

export type Props = {
  companyNameChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  expressFlagChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  companyName: string;
  buttunState: boolean;
  email: string;
  expressFlag: boolean;
};

const InputForm: FC<Props> = ({
  companyNameChangeHandler,
  emailChangeHandler,
  expressFlagChangeHandler,
  onClickHandler,
  companyName,
  buttunState,
  email,
  expressFlag,
}) => {
  return (
    <>
      <section className="container">
        <h2 className="container__heading">情報を入力</h2>
        <form className="textbox">
          <ul className="input">
            <li className="input__item">
              <label className="input__label">
                貴社名<span className="required">※必須</span>
              </label>
              <input
                type="text"
                className="input__textbox"
                onChange={companyNameChangeHandler}
                value={companyName}
                placeholder="あなたの所属する会社名を入力してください"
                required
              />
            </li>
            <li className="input__item">
              <label className="input__label">
                メールアドレス<span className="required">※必須</span>
              </label>
              <input
                type="text"
                className="input__textbox"
                onChange={emailChangeHandler}
                value={email}
                placeholder="あなたのEメールアドレスを入力してください"
              />
              <p className="input__textbox-notice">
                ※
                ご入力いただいたメールアドレスは、本サービスに関するご案内、ヒアリング等の目的で利用されることがあります
              </p>
            </li>
            {expressFlag ? (
              <li className="input__item">
                <label className="input__checkbox">
                  <input type="checkbox" onChange={expressFlagChangeHandler} />
                  <span className="input__check" />
                </label>
                <label className="input__label optional">特急対応を依頼</label>
              </li>
            ) : null}
          </ul>
          <p className="input__btn-notice">
            <a href="/terms-of-service" target="_blank">
              利用規約
            </a>
            に同意の上、送信ボタンをクリックしてください
          </p>
          <button type="submit" value="送信" disabled={!buttunState} onClick={onClickHandler} className="input__btn">
            送信
          </button>
        </form>
      </section>
    </>
  );
};

export default InputForm;
