import UploadError from "components/Error/Upload";
import SetFileMessage from "components/Message/SetFile";
import InputForm from "components/UploadForm/InputForm";
import Success from "components/UploadForm/Success";
import UploadArea from "components/UploadForm/UploadArea";
import { UploadState } from "components/UploadForm/container";
import { Organization } from "models/OrganizationData";
import React, { FC } from "react";

import "./index.css";

type FileType = "success" | "failed" | "trash";

export type UploadFile = File & {
  fileState: FileType;
};

export type UploadFormProps = {
  organization: Organization;
  setFileState: (fileName: string, state: string) => void;
  reset: () => void;
  onClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
  companyNameChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  expressFlagChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  uploadState: UploadState;
  companyName: string;
  animation: boolean;
  setAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  buttunState: boolean;
};

const UploadForm: FC<UploadFormProps> = (props) => {
  return (
    <>
      <main className="main">
        <UploadArea
          files={props.files}
          setFiles={props.setFiles}
          uploadState={props.uploadState}
          setAnimation={props.setAnimation}
          organization={props.organization}
        />
        {props.uploadState === "success" ? <Success clickHandler={() => props.reset()} /> : null}
        {props.uploadState === "failed" ? <UploadError clickHandler={() => props.reset()} /> : null}
        {props.uploadState === "normal" ? (
          <InputForm
            companyNameChangeHandler={props.companyNameChangeHandler}
            emailChangeHandler={props.emailChangeHandler}
            expressFlagChangeHandler={props.expressFlagChangeHandler}
            onClickHandler={props.onClickHandler}
            companyName={props.companyName}
            buttunState={props.buttunState}
            email={props.email}
            expressFlag={props.organization.express}
          />
        ) : null}
      </main>

      <footer className="footer">© Sansan, Inc.</footer>

      <SetFileMessage animation={props.animation} setAnimation={props.setAnimation} />
    </>
  );
};

export default UploadForm;
