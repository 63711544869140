import { OrganizationCode } from "models/OrganizationData";
import * as React from "react";

import { ApiClientBase } from "../../lib/ApiClientBase";
import { JesApiClient } from "../../lib/JesApiClient";

export const ApiClientContext = React.createContext<ApiClientBase | null>(null);

export const ApiClientProvider: React.FC<{ organizationCode: OrganizationCode | null }> = ({
  organizationCode,
  children,
}) => {
  let apiClient: ApiClientBase | null = null;

  if (!organizationCode) {
    // nothing
  } else {
    apiClient = JesApiClient.instance;
  }
  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>;
};
