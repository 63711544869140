import "./Modal.css";
import ImgModal from "img/img-modal.svg";

import React from "react";

const SANSAN_HELP_PAGE = "https://jp-help.sansan.com/hc/ja/articles/900004637743";

export type HelpModalProps = {
  setShowHelpModal: (value: boolean) => void;
};

const HelpModal: React.FC<HelpModalProps> = ({ setShowHelpModal }) => (
  <div className="modal">
    <div className="modal__box modal__about">
      <p className="modal__heading">人事異動の情報を、名刺交換したSansanユーザーに通知</p>
      <div className="modal__btn-close" onClick={() => setShowHelpModal(false)} />
      <div className="modal__message">
        <img src={ImgModal} className="img-modal" />
      </div>
      <div className="modal__link-howto">
        {/* NOTE: 自社ページへのリンクのため、noopener を付けない https://developer.mozilla.org/ja/docs/Web/HTML/Link_types */}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={SANSAN_HELP_PAGE} target="_blank">
          詳しい使い方はこちら
        </a>
      </div>
    </div>
  </div>
);

export default HelpModal;
