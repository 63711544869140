import Error from "components/Error";
import Header from "components/Header";
import HelpModal from "components/Message/HelpModal";
import Modal, { ModalProps } from "components/Message/Modal";
import UploadFormContainer from "components/UploadForm/container";
import { Organization } from "models/OrganizationData";
import React, { FC } from "react";

import "components/Home/index.css";

export type Props = {
  organization: Organization;
  error: boolean;
  modal: ModalProps;
  setModal: (value: ModalProps) => void;
  closeModal: () => void;
  showHelpModal: boolean;
  setShowHelpModal: (value: boolean) => void;
};

const Home: FC<Props> = ({ organization, error, modal, setModal, closeModal, showHelpModal, setShowHelpModal }) => {
  return (
    <div className="content-wrap">
      <Header organization={organization} setShowHelpModal={setShowHelpModal} />
      <div className="container">
        {error ? (
          <Error />
        ) : (
          <UploadFormContainer organization={organization} setModal={setModal} closeModal={closeModal} />
        )}
      </div>
      <Modal {...modal} />
      {showHelpModal && <HelpModal setShowHelpModal={setShowHelpModal} />}
    </div>
  );
};

export default Home;
