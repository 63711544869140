import Icon from "components/Icon";
import React, { FC } from "react";
import "components/UploadForm/Trash.css";

type FileType = "success" | "failed" | "trash";

export type Props = {
  id: number;
  name: string;
  size: number;
  clickHandler: () => void;
  type: FileType;
};

const Trash: FC<Props> = ({ id, name, size, clickHandler, type }) => {
  const kb: number = Math.floor(size / 1000);

  return (
    <>
      <li className="uploader__item" key={id}>
        <p className="uploader__file">
          <span className="uploader__filename">{name}</span>
          <span className="uploader__volume">({kb}KB)</span>
        </p>
        <span className="icon-trash remove-btn" onClick={clickHandler}>
          <Icon type={type} />
        </span>
      </li>
    </>
  );
};

export default Trash;
