import { ApiClientContext } from "components/ApiClient";
import { ModalProps } from "components/Message/Modal";
import UploadForm from "components/UploadForm";
import { Organization } from "models/OrganizationData";
import React, { FC, useState, useCallback, useEffect } from "react";

type FileType = "success" | "failed" | "trash";

export type UploadState = "success" | "failed" | "normal";

export type UploadFile = File & {
  fileState: FileType;
};

export type UploadFormContainerProps = {
  organization: Organization;
  setModal: (value: ModalProps) => void;
  closeModal: () => void;
};

const UploadFormContainer: FC<UploadFormContainerProps> = ({ organization, setModal, closeModal }) => {
  const [uploadState, setUploadState] = useState<UploadState>("normal");
  const [buttunState, setButtonState] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [expressFlag, setExpressFlag] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [files, setFiles] = useState<UploadFile[]>([]);

  const apiClient = React.useContext(ApiClientContext);

  const success = useCallback(() => {
    return files.filter((file) => file.fileState === "failed").length === 0;
  }, [files]);

  const validateEmail = useCallback(() => {
    // eslint-disable-next-line no-control-regex
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(email.toLowerCase());
  }, [email]);

  const validate = useCallback(() => {
    return (
      companyName.length > 0 && companyName.length < 200 && files.length > 0 && files.length < 11 && validateEmail()
    );
  }, [companyName.length, files.length, validateEmail]);

  useEffect(() => {
    validate() ? setButtonState(true) : setButtonState(false);
  }, [companyName, files, validate]);

  useEffect(() => {
    success();
  }, [files, success]);

  const upload = async () => {
    if (!apiClient) {
      // apiClientがnullの場合は強制的にアップロードエラーを表示させます(non-null assertionの指摘対応)
      setUploadState("failed");
    } else {
      const uploadId: string = await apiClient.uploadId().then((d) => d.upload_id); // await fetchUploadId();

      const results = await apiClient.uploadAll(files, uploadId, companyName, email, expressFlag);

      files.forEach((file, idx) => {
        if (results && results[idx] !== null) {
          setFileState(file.name, "success");
        } else {
          setFileState(file.name, "failed");
        }
      });

      success() ? setUploadState("success") : setUploadState("failed");
    }
  };

  const setFileState = (fileName: string, state: string) => {
    const uploadFiles: UploadFile[] = files.map((file) => {
      if (file.name === fileName) {
        Object.assign(file, { fileState: state });
      }
      return file;
    });

    setFiles(uploadFiles);
  };

  const reset = () => {
    setUploadState("normal");
    setButtonState(false);
    setCompanyName("");
    setEmail("");
    setExpressFlag(false);
    setFiles([]);
  };

  const confirm = () => {
    setModal({
      heading: "アップロード内容の確認",
      text: "以下の資料はアップロードしてもデータ化されません",
      list: ["他社の人事異動が掲載されている資料", "Webニュースや新聞等、自社以外が発行した資料"],
      okText: "送信",
      okHandler: () => {
        closeModal();
        upload();
      },
      cancelText: "キャンセル",
      cancelHandler: () => {
        setFiles([]); // 一度ファイルをリセットする
        closeModal();
        // ファイルをリセットしたことをユーザーに知らせる。
        // モーダルを閉じた後にブラウザダイアログを表示したいが、window.alert は UI スレッドをブロックするので、window.setTimeout で回避する
        // ref: https://medium.com/@yn2011/ui%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89%E3%81%A8window-alert-c3aafa8c8f62
        window.setTimeout(() => window.alert("ファイルを再度アップロードしてください"), 10); // eslint-disable-line no-alert
      },
    });
  };

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    setButtonState(false);
    if (!validate()) return;

    e.preventDefault();
    // URL を一般公開している組織の場合、確認ポップアップを入れる
    organization.public ? confirm() : upload();
  };

  const getTargetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    return e.target.value.trimEnd();
  };

  const companyNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(getTargetValue(e));
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(getTargetValue(e));
  };

  const expressFlagChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpressFlag(e.target.checked);
  };

  return (
    <UploadForm
      organization={organization}
      setFileState={setFileState}
      reset={reset}
      onClickHandler={onClickHandler}
      companyNameChangeHandler={companyNameChangeHandler}
      emailChangeHandler={emailChangeHandler}
      expressFlagChangeHandler={expressFlagChangeHandler}
      files={files}
      setFiles={setFiles}
      uploadState={uploadState}
      companyName={companyName}
      animation={animation}
      setAnimation={setAnimation}
      email={email}
      buttunState={buttunState}
    />
  );
};

export default UploadFormContainer;
