import AboutForSansan from "components/Header/AboutForSansan";
import LabsLogo from "img/logo_labs.svg";
import { Organization } from "models/OrganizationData";
import React, { FC } from "react";
import "components/Header/index.css";

const LABS_MENU_PAGE = "https://ap.sansan.com/v/labs/";

export type Props = {
  organization: Organization;
  setShowHelpModal: (value: boolean) => void;
};

const Header: FC<Props> = ({ organization, setShowHelpModal }) => {
  return (
    <>
      <header className="header">
        <h1 className="header__heading">
          {organization.code === "sansan" ? (
            <>
              <a href={LABS_MENU_PAGE}>
                <img src={LabsLogo} className="header__logo" alt="Sansan Labs" />
              </a>
              接点ユーザーへの人事異動ニュース配信
            </>
          ) : (
            `${organization.name} 人事異動情報 送信システム`
          )}
        </h1>
      </header>
      {organization.code === "sansan" && <AboutForSansan setShowHelpModal={setShowHelpModal} />}
    </>
  );
};

export default Header;
