import { useModal } from "hooks/userModal";
import { JesApiClient } from "lib/JesApiClient";
import { findOrganization, Organization, getMetaInfo } from "models/OrganizationData";
import { parse } from "query-string";
import React, { useState, FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ApiClientContext, ApiClientProvider } from "../ApiClient";
import Home from "./index";

import "components/Home/index.css";

const WithAuthentication = (props: { render: ({ error }: { error: boolean }) => React.ReactElement }) => {
  const apiClient = React.useContext(ApiClientContext);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    apiClient &&
      apiClient.me().then(
        () => {
          setError(false);
        },
        () => {
          setError(true);
        }
      );
  }, [apiClient]);

  return <>{props.render({ error })}</>;
};

const HomeContainer: FC<{}> = () => {
  const [error, setError] = useState(false);
  const [organization, setOrganization] = useState<Organization>({
    code: null,
    name: "",
    express: false,
    public: false,
  });
  const [modal, setModal, closeModal] = useModal();
  const [showHelpModal, setShowHelpModal] = useState(false);

  const location = useLocation();
  const token = parse(location.search).cid;

  const metaInfo = getMetaInfo(organization);

  useEffect(() => {
    if (!token) {
      setError(true); // token がなければエラー画面を表示する
      return;
    }

    JesApiClient.instance.myOrganization().then(
      (result) => {
        const org = findOrganization(result.contract_organization_code);
        if (org === undefined) {
          throw new Error("存在しない組織コードです！！");
        }
        setOrganization(org);
      },
      (e) => {
        setError(e);
      }
    );
  }, [token]);

  return (
    <ApiClientProvider organizationCode={organization.code}>
      <WithAuthentication
        render={({ error: authError }) => (
          <>
            <Helmet>
              <title>{metaInfo.title}</title>
              <meta name="description" content={metaInfo.description} />
            </Helmet>
            <Home
              error={error || authError}
              organization={organization}
              modal={modal}
              setModal={setModal}
              closeModal={closeModal}
              showHelpModal={showHelpModal}
              setShowHelpModal={setShowHelpModal}
            />
          </>
        )}
      />
    </ApiClientProvider>
  );
};

export default HomeContainer;
