import React, { FC } from "react";

const Error: FC<{}> = () => {
  return (
    <>
      <div className="announce__failed">
        <p className="announce__failed__text">エラーが発生しました</p>
      </div>
    </>
  );
};

export default Error;
