import Icon from "components/Icon";
import React, { FC } from "react";

export type Props = {
  clickHandler: () => void;
};

const UploadError: FC<Props> = ({ clickHandler }) => {
  return (
    <>
      <div className="announce">
        <p className="announce__text">
          <Icon type="failed" />
          送信に失敗しました
        </p>
        <div className="announce__failed">
          <p className="announce__failed__text">
            エラーが発生し、送信できませんでした。
            <br />
            お手数ですが、以下を確認し再度送信してください。
          </p>
          <ul className="announce__failed__list">
            <li className="announce__failed__item">ファイル形式（PDF・JPG・PNG・EXCEL・txt・csv・word）</li>
            <li className="announce__failed__item">ファイルサイズ（合計で30MBまで）</li>
            <li className="announce__failed__item">アップロード数（最大10まで）</li>
          </ul>
        </div>
        <span className="announce__link" onClick={clickHandler}>
          送信画面に戻る
        </span>
      </div>
    </>
  );
};

export default UploadError;
