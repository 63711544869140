import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import "components/Message/SetFile.css";

export type Props = {
  animation: boolean;
  setAnimation: React.Dispatch<React.SetStateAction<boolean>>;
};

const SetFileMessage: FC<Props> = ({ animation, setAnimation }) => {
  return (
    <>
      <CSSTransition in={animation} classNames="fade" timeout={5000} onEntered={() => setAnimation(false)}>
        <div className="message">
          <p className="message__text">
            <span className="icon-check" />
            ファイルがリストに追加されました。
          </p>
        </div>
      </CSSTransition>
    </>
  );
};

export default SetFileMessage;
