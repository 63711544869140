import Trash from "components/UploadForm/Trash";
import { UploadFile } from "components/UploadForm/index";
import { Organization } from "models/OrganizationData";
import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "components/UploadForm/UploadArea.css";

type Props = {
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  uploadState: string;
  setAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  organization: Organization;
};

const UploadArea: FC<Props> = ({ files, setFiles, uploadState, setAnimation, organization }) => {
  const deleteFile = (name: string) => {
    const uploadFiles: UploadFile[] = files.filter((f) => f.name !== name);

    setFiles(uploadFiles);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadFiles: UploadFile[] = files
        .concat(acceptedFiles)
        .map((file) => Object.assign(file, { fileState: "trash" }));

      setFiles(uploadFiles);
      setAnimation(true);
    },
    [files, setFiles, setAnimation]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const CautionMessage: FC<{}> = () => (
    <p className="uploader__caution">
      自分の所属企業または関連企業以外の資料は
      <br />
      アップロードできません
    </p>
  );

  return (
    <section className="container">
      <h2 className="container__heading">資料をアップロード</h2>
      {uploadState === "normal" ? (
        <form action="" method="post" encType="multipart/form-data" className="uploader">
          <div className="uploader__box" {...getRootProps()}>
            <p className="uploader__guide">
              ファイルをドラッグ&ドロップ<span>または</span>
            </p>
            <label className="uploader__btn">クリックしてファイルを選択</label>
            <input {...getInputProps()} />
            {organization.public && <CautionMessage />}
            <p className="uploader__notice">
              ファイル形式（PDF・JPG・PNG・EXCEL・txt・csv・word）アップロードできるファイル数は10まで、サイズの合計は30MBまで
            </p>
          </div>
        </form>
      ) : null}
      <ul className="uploader__list">
        {files.map((f) => (
          <Trash
            key={f.lastModified}
            id={f.lastModified}
            name={f.name}
            size={f.size}
            clickHandler={() => deleteFile(f.name)}
            type={f.fileState}
          />
        ))}
      </ul>
    </section>
  );
};

export default UploadArea;
