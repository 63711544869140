import { ModalProps, initialProps } from "components/Message/Modal";
import { useState } from "react";

export const useModal = (): [ModalProps, (value: ModalProps) => void, () => void] => {
  const [modal, setModal] = useState<ModalProps>(initialProps);

  const closeModal = () => {
    setModal(initialProps);
  };

  return [modal, setModal, closeModal];
};
