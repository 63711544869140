import Icon from "components/Icon";
import React, { FC } from "react";

export type Props = {
  clickHandler: () => void;
};

const Success: FC<Props> = ({ clickHandler }) => {
  return (
    <>
      <div className="announce">
        <p className="announce__text">
          <Icon type="success" />
          送信に成功しました
        </p>
        <div className="announce__success">
          <p className="announce__success__text">
            データ化が完了次第、「人事異動・連絡先変更」として、
            <br />
            該当人物の名刺をもつユーザーにニュース配信します。
          </p>
        </div>
        <span className="announce__link" onClick={clickHandler}>
          送信画面に戻る
        </span>
      </div>
    </>
  );
};

export default Success;
