export type OrganizationCode = "sansan" | "kyodo" | "nikkan" | "nikkan_kensetsu";

export type Organization = {
  code: OrganizationCode | null;
  name: string;
  express: boolean;
  public: boolean; // NOTE: false の場合、自社利用のみを想定
};

type MetaInfo = {
  title: string;
  description: string;
};

export const getMetaInfo = (organization: Organization): MetaInfo => {
  return organization.code === "sansan"
    ? {
        title: "Sansan Labs | 接点ユーザーへの人事異動ニュース配信",
        description:
          "自社の人事異動の資料をアップロードすると、名刺所有者に「人事異動・連絡先変更」のニュースを配信できます。",
      }
    : {
        title: "Sansan | 人事異動情報 送信システム",
        description: "自社の人事異動情報が掲載された資料をアップロードすると、データ化されます。",
      };
};

const organizationData: Organization[] = [
  {
    code: "sansan",
    name: "Sansan株式会社",
    express: false,
    public: true,
  },
  {
    code: "kyodo",
    name: "共同通信",
    express: true,
    public: false,
  },
  {
    code: "nikkan",
    name: "日刊工業新聞",
    express: false,
    public: true,
  },
  {
    code: "nikkan_kensetsu",
    name: "日刊建設工業新聞",
    express: false,
    public: false,
  },
];

export const findOrganization = (code: OrganizationCode): Organization | undefined => {
  return organizationData.find((data) => data.code === code);
};
