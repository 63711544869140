import Failed from "img/icon-failed.svg";
import Success from "img/icon-success.svg";
import Trash from "img/icon-trash.svg";
import React, { FC } from "react";

type IconType = {
  failed: string;
  success: string;
  trash: string;
};

const iconDict: IconType = {
  failed: Failed,
  success: Success,
  trash: Trash,
};

export type IconProps = { type: keyof IconType };

const IconComponent: FC<IconProps> = ({ type }) => {
  return (
    <>
      <img src={iconDict[type]} />
    </>
  );
};

export default IconComponent;
