import React, { FC } from "react";

import "./index.css";

export type TermsOfServiceProps = {};

const TermsOfService: FC<TermsOfServiceProps> = () => {
  return (
    <main className="main">
      <div className="terms-of-service">
        <h2 className="container__heading">サービス利用規約</h2>
        <ul className="terms__list">
          <li className="terms__item">
            1.お客様は、本サービスにアップロードされた資料、情報等（以下単に「資料等」という）が以下の通り取り扱われることに同意の上、当該資料等をアップロードするものとします。
            <ul className="terms__list-secondary">
              <li className="terms__item-secondary">
                (1)資料等に含まれる人事異動情報は、弊社が定めた入力ルールに則ってデータ化されます（以下、データ化された人事異動情報を「人事異動データ」という）。現在データ化に対応している資料は人事異動情報のみとなり、資料等に人事異動情報以外の情報が含まれる場合、当該情報はデータ化の対象とはなりません。
              </li>
              <li className="terms__item-secondary">
                (2)お客様による資料等のアップロード後は、当該資料等の内容の削除・修正はできかねます。
              </li>
              <li className="terms__item-secondary">
                (3)人事異動データに関する所有権・著作権・商標権・特許権その他一切の権利は、全て当社に帰属するものとします。
              </li>
              <li className="terms__item-secondary">
                (4)当社は、個人情報が特定されることがないよう、人事異動データを処理した情報について、当社が提供するサービスのために利用し、又は一般に公表若しくは提供する場合があります。
              </li>
            </ul>
          </li>
          <li className="terms__item">
            2.お客様がアップロードされた資料等のデータ化が完了した際に、お客様に対する通知等は行っておりません。
          </li>
          <li className="terms__item">
            3.お客様は、自らの所属企業又は関連企業以外に関する資料等を本サービスにアップロードすることはできません。
          </li>
        </ul>
      </div>
    </main>
  );
};

export default TermsOfService;
